<template>
    <div v-if="isMobile()">
        <router-view />
    </div>
    <div v-else>
        <div class="banner"><img src="../../assets/images/header3.jpg" alt=""></div>
        <div class="introduce">
            <div class="introduce-left">
                <p class="title">新闻中心</p>
                <p :class="['text',clkShow==='Dynamic'?'active':'']" @click="goto('Dynamic')">集团动态</p>
                <p :class="['text',clkShow==='Video'?'active':'']" @click="goto('Video')">视频中心</p>
            </div>
            <div class="introduce-right">
                <div class="step">
                    当前位置： 首页 > 新闻中心 > {{title}}
                </div>
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
export default {
    setup() {
        const clkShow = ref('Introduce')
        const title = ref('集团动态')
        const router = useRouter()

        // 判断浏览器函数
        const isMobile = () => {
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }

        clkShow.value = router.currentRoute.value.name
        title.value = router.currentRoute.value.meta.title

        const goto = (name)=> {
            clkShow.value = name
            router.push({
                name: name
            })
        }
        
        onBeforeRouteUpdate((to) => {
            clkShow.value = to.name
            title.value = to.meta.title
        })

        return {
            goto,
            clkShow,
            title,
            isMobile
        }
    },
}
</script>
<style lang="scss" scoped>
    .banner{
        height: 415px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .introduce {
        width: 1330px;
        margin: 0 auto;
        display: flex;
        margin-top: 10px;
        padding-bottom: 50px;
        .introduce-left {
            width: 240px;
            height: 147px;
            border-radius: 5px;
            background: #f6f6f6;
            color: #fff;
            overflow: hidden;
            cursor: pointer;
            .title {
                line-height: 50px;
                background: #1e50ae;
                font-size: 17px;
                padding-left: 10px;
            }
            .text {
                line-height: 48px;
                color: #666666;
                margin: 0 5px;
                border-bottom: 1px dashed #d1d1d1;
                padding-left: 10px;
            }
            .active { 
                color: #1e50ae
            }
        }
        .introduce-right {
            margin-left: 10px;
            width: calc(100% - 240px);
            .step {
                font-size: 14px;
                padding: 20px 7px;
                border-bottom: 1px solid #999;
            }
        }
    }
</style>